/** Dependencies */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Grid, Stack, keyframes } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Modal from '../../../../../components/Modal';
import FormSelect from '../../../../../components/FormSelect';
import FastPaymentModalCustomer from './FastPaymentModalCustomer';
import Icon from '../../../../../components/Icon';

/** Hooks */
import usePosOrder from '../../../../../hooks/usePosOrder';
import useSettings from '../../../../../hooks/useSettings';

/** Helpers */
import { priceFormatter } from '../../../../../helpers/moneyFormatter';

/** Types */
import { SelfPayment } from '../PaymentModal/types';

/** Styles */
import styles from '../styles';

const blink = keyframes` from { color: white } to { color: red }`;

interface Props {
  open: boolean;
  onClose: () => void;
  totalPrice: number;
  tableId: string;
  onBackClick: () => void;
  currencyCode: string;
  handleSelfSubmit?: (selfPayment: SelfPayment | null) => void;
  isSelfOrder: boolean;
  isSelfCreate: boolean;
  selectedOrderTypeId?: number;
}

function FastPaymentModal({
  open,
  onClose,
  totalPrice,
  tableId,
  onBackClick,
  currencyCode,
  handleSelfSubmit,
  isSelfOrder,
  isSelfCreate,
  selectedOrderTypeId,
}: Props) {
  const { addOrderTransactionPayment } = usePosOrder();
  const { meProfile, fetchMeWithPaymentTypes } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [paymentTypeId, setPaymentTypeId] = useState<string>('12');
  const [paymentCustomer, setPaymentCustomer] = useState(false);

  const { t } = useTranslation('pos');

  const options = [
    { id: '12', text: t('paymentModal.pay_close') },
    { id: '1', text: t('paymentModal.pay') },
  ];

  const handleClose = () => {
    onClose();
  };

  const handleAddPaymentClick = async (id: string, customer_id?: string) => {
    if (isSelfOrder && handleSelfSubmit) {
      handleSelfSubmit({
        payment_action: Number(paymentTypeId),
        payments: [
          {
            amount: totalPrice * 100,
            payment_type_id: Number(id),
            customer_id: customer_id || null,
            remainder_amount: 0,
          },
        ],
      });
    } else {
      await addOrderTransactionPayment({
        order_transaction_id: tableId,
        payment_action: Number(paymentTypeId),
        payments: [
          {
            amount: Number((totalPrice * 100).toFixed(2)),
            payment_type_id: Number(id),
            customer_id: customer_id || null,
            remainder_amount: 0,
          },
        ],
      });
      handleClose();
    }
    if (!isSelfCreate) onBackClick();

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      title={`${t('paymentModal.fast_pay')} (${priceFormatter(
        totalPrice,
        currencyCode,
      )})`}
      subtitle={t('paymentModal.cash_order')}
    >
      <div>
        <Stack
          sx={styles.fastPaymentStack}
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
        >
          <Grid container item>
            <Grid xs={12}>
              <FormSelect
                label={t('paymentModal.type_operation')}
                value={paymentTypeId}
                onChange={event =>
                  setPaymentTypeId(event.target.value as string)
                }
                underline
                fullWidth
                options={options}
              />
            </Grid>
            <br />
            <Grid
              sx={{ mt: 3 }}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              {meProfile?.paymentTypes?.map(
                item =>
                  item.is_active && (
                    <Grid sx={{ p: 1 }} item xs={6}>
                      <Button
                        color="inherit"
                        fullWidth
                        sx={styles.paymentTypeButton}
                        onClick={() => {
                          if (item.id === 9) {
                            setPaymentCustomer(true);
                          } else {
                            handleAddPaymentClick(item.id.toString());
                          }
                        }}
                      >
                        <Grid justifyContent="center">
                          {item.id === selectedOrderTypeId && (
                            <Grid
                              sx={{
                                position: 'absolute',
                                top: 6,
                                right: 6,
                                animation: `${blink} 2s linear infinite`,
                                opacity: '0.5',
                              }}
                            >
                              <Icon name="Circle" />
                            </Grid>
                          )}
                          <Grid>
                            <img
                              src={item.logo_url}
                              alt=""
                              width="40px"
                              height="40px"
                            />
                          </Grid>
                          <Grid>{item.name}</Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  ),
              )}
            </Grid>
            <FastPaymentModalCustomer
              open={paymentCustomer}
              handleAddPaymentClick={(id, customer_id) =>
                handleAddPaymentClick(id, customer_id)
              }
              onClose={() => setPaymentCustomer(false)}
            />
          </Grid>
        </Stack>
      </div>
    </Modal>
  );
}

export default FastPaymentModal;
