/** Dependencies */
import React, { useState, useEffect } from 'react';
import { format, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  IconButton,
  MenuItem as MenuItemComponent,
  Pagination,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Tooltip,
  Chip,
  Typography,
} from '@mui/material';

/** Components */
import LoadingState from '../../../../components/LoadingState';
import EmptyState from '../../../../components/EmptyState';
import StopWatch from '../../../../components/Timer/StopWatch';
import Icon from '../../../../components/Icon';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import LazyImage from '../../../../helpers/LazımImage';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import { singlePriceFormatter } from '../../../../helpers/moneyFormatter';

/** Hooks */
import useOrders from '../../../../hooks/useOrders';
import useProfile from '../../../../hooks/useProfile';

/** Styles */
import generalStyles from '../../../../assets/stylesheets/generalStyles';

/** Services */
import { GroupedDataColumnType } from '../../../../services/api/orders/types';
import useSelectedOptions from './useSelectedOptions';

interface Props {
  selectActive: number;
  page: number;
  handleChange: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => Promise<void>;
  handlePrintTable: (tableId: string) => Promise<void>;
  handleSetStatus: (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => Promise<void>;
  setType: (value: ((prevState: number) => number) | number) => void;
  setOrder: (
    value:
      | ((
          prevState: GroupedDataColumnType | undefined,
        ) => GroupedDataColumnType | undefined)
      | GroupedDataColumnType
      | undefined,
  ) => void;
  setOrderId: (value: ((prevState: string) => string) | string) => void;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setDelivererModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setDelivererId: (value: ((prevState: string) => string) | string) => void;
  setStatusId: (value: ((prevState: number) => number) | number) => void;
  setFastPaymentModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setOpenLastTransactions: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  isShowLastOrders: string;
  setIsShowLastOrders: (
    value: ((prevState: string) => string) | string,
  ) => void;
  isShowAddress: string;
  setIsShowAddress: (value: ((prevState: string) => string) | string) => void;
  groupedOrders: GroupedDataColumnType[] | null;
  meta: any;
}

interface ElegantStopWatchProps {
  startTime: Date | string;
  endTime: Date | string | null | undefined;
  isActive: boolean;
}

// eslint-disable-next-line react/function-component-definition
const ElegantStopWatch: React.FC<ElegantStopWatchProps> = ({
  startTime,
  endTime,
  isActive,
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const calculateElapsedTime = () => {
      const start = startTime instanceof Date ? startTime : new Date(startTime);
      const end = endTime
        ? endTime instanceof Date
          ? endTime
          : new Date(endTime)
        : new Date();
      return differenceInSeconds(end, start);
    };

    setElapsedTime(calculateElapsedTime());

    if (!endTime) {
      const timer = setInterval(
        () => setElapsedTime(calculateElapsedTime()),
        1000,
      );
      return () => clearInterval(timer);
    }
  }, [startTime, endTime]);

  const formatCompactTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatDetailedTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';
    if (hours > 0) result += `${hours} saat `;
    if (minutes > 0 || hours > 0) result += `${minutes} dakika `;
    result += `${remainingSeconds} saniye`;
    return result.trim();
  };

  const formatTime = (time: Date | string | null | undefined) => {
    if (!time) return '';
    const date = time instanceof Date ? time : new Date(time);
    return format(date, 'dd.MM.yyyy HH:mm');
  };

  const getBackgroundColor = () => {
    if (!isActive || endTime) return '#e0e0e0';
    return elapsedTime >= 1800 ? '#f44336' : '#4caf50';
  };

  const getTextColor = () => {
    if (!isActive || endTime) return '#424242';
    return '#ffffff';
  };

  return (
    <div
      style={{
        display: 'inline-block',
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: getBackgroundColor(),
        color: getTextColor(),
        fontSize: '14px',
        fontWeight: 'bold',
        transition: 'background-color 0.5s ease-in-out',
      }}
    >
      {formatCompactTime(elapsedTime)}
    </div>
  );
};

function OrdersTableHeader({
  selectActive,
  page,
  handleChange,
  handlePrintTable,
  handleSetStatus,
  setType,
  setOrder,
  setOrderId,
  setOpen,
  setDelivererModal,
  setDelivererId,
  setStatusId,
  setFastPaymentModal,
  setOpenLastTransactions,
  isShowLastOrders,
  setIsShowLastOrders,
  isShowAddress,
  setIsShowAddress,
  groupedOrders,
  meta,
}: Props) {
  const { t } = useTranslation('orders');
  const { groupedOrdersLoading, getOrderLastTransactions } = useOrders();
  const navigate = useNavigate();
  const { meProfile } = useProfile();

  const hasSetDelivererPermission = keyHasPermission(
    'set-order-transaction-deliverer',
    meProfile?.permissions || [],
  );

  const selectedOptionsArray = useSelectedOptions(
    groupedOrders?.map(order => ({
      statusId: order.status_id,
      typeId: order.type_id,
      integrator: order.integrator,
      deliveryType: order.delivery_type,
    })) || [],
  );

  const getStatusColor = (status_id: number) => {
    switch (status_id) {
      case 1:
        return '#fbc02d';
      case 2:
        return '#f9a825';
      case 3:
        return '#f57f17';
      case 5:
        return '#00e676';
      case 16:
        return '#00c853';
      default:
        return '#00c853';
    }
  };

  const formatDate = (dateString: Date) => {
    return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
  };

  return (
    <Grid>
      {groupedOrdersLoading ? (
        <Grid alignItems="center" display="flex" justifyContent="center">
          <LoadingState />
        </Grid>
      ) : !groupedOrders?.length ? (
        <Grid alignItems="center" display="flex" justifyContent="center">
          <EmptyState message={t('order.no_order')} />
        </Grid>
      ) : (
        <Table
          sx={{ minWidth: 781, overflowX: 'auto' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('order.table.time')}</TableCell>
              <TableCell align="center">{t('order.table.details')}</TableCell>
              <TableCell align="center">
                {t('order.table.customer_details')}
              </TableCell>
              <TableCell align="center">
                {t('order.table.amount_type')}
              </TableCell>
              <TableCell align="center">
                {t('order.table.order_date')}
              </TableCell>
              {selectActive !== 0 && (
                <TableCell align="center">
                  {t('order.table.order_end_date')}
                </TableCell>
              )}
              <TableCell align="center">{t('order.table.status')}</TableCell>
              <TableCell align="center">
                {t('order.table.operations')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedOrders?.map((item, index) => {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const selectedOptions = selectedOptionsArray[index];

              return (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    <ElegantStopWatch
                      startTime={item.created_at}
                      endTime={selectActive === 0 ? null : item.ended_at}
                      isActive={selectActive === 0}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container justifyContent="center" alignItems="center">
                      {item.integrator.logo && (
                        <Grid mr={1}>
                          <LazyImage src={item.integrator.logo} width="40" />
                        </Grid>
                      )}
                      <Grid>
                        <Grid
                          xs={12}
                          sx={{
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: '500',
                          }}
                        >
                          {item.integrator?.slug === 'trendyol'
                            ? `Sipariş No: ${item.integration?.order_number}`
                            : `Sipariş No: ${item.transaction_number}`}
                        </Grid>
                        <Grid
                          xs={12}
                          sx={{
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                          }}
                        >
                          {item.type}
                          {item.delivery_type &&
                            item.delivery_type === 'GO' &&
                            ` (${item.delivery_type})`}
                        </Grid>
                        {(item.integrator.slug === 'kitchrest' ||
                          item.integrator.slug === 'trendyol') && (
                          <Grid
                            sx={{
                              color: '#d32f2f',
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                              cursor: 'pointer',
                              mt: 1,
                            }}
                            onClick={() => {
                              if (
                                item.integrator.slug === 'trendyol' &&
                                item.integration?.restaurant_id &&
                                item.integration?.order_id
                              ) {
                                window.open(
                                  `https://partner.trendyol.com/meal/${item.integration?.restaurant_id}/order/list/details/${item.integration?.order_id}`,
                                  '_blank',
                                );
                              } else if (item.type_id === 3) {
                                navigate(`/app/pos/order/package/${item.id}`, {
                                  state: {
                                    tableName: null,
                                    full: false,
                                  },
                                });
                              } else if (item.type_id === 2) {
                                navigate(`/app/pos/order/self/${item.id}`, {
                                  state: {
                                    tableName: null,
                                    full: false,
                                  },
                                });
                              }
                            }}
                          >
                            {t('order.order_details')}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    {item.customer ? (
                      <Grid>
                        <Grid container>
                          {item.customer.order_transactions_count === 1 && (
                            <Grid
                              xs={12}
                              sx={{
                                ...generalStyles.generalDisplay,
                                textAlign: 'center',
                                fontSize: '13px',
                                marginBottom: '4px',
                              }}
                            >
                              <Chip
                                sx={{ height: '20px', fontSize: '12px' }}
                                label="Yeni Müşteri"
                                color="success"
                              />
                            </Grid>
                          )}
                          <Grid
                            xs={12}
                            sx={{
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                            }}
                          >
                            {`${item?.customer?.first_name || ''} ${
                              item?.customer?.last_name || ''
                            }`}
                          </Grid>
                          <Grid
                            xs={12}
                            sx={{
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                            }}
                          >
                            {item?.customer?.phone_number || ''}
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{
                            color: '#d32f2f',
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                            cursor: 'pointer',
                            marginTop: '10px',
                          }}
                          onClick={() =>
                            setIsShowAddress(
                              isShowAddress === item.id ? '' : item.id,
                            )
                          }
                        >
                          {t('order.address')}
                        </Grid>
                        {isShowAddress === item.id && (
                          <Grid sx={{ fontSize: '12px' }} mt={1}>
                            {item.customer.address || t('order.no_address')}
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      '_'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Grid
                      xs={12}
                      sx={{
                        ...generalStyles.generalDisplay,
                        textAlign: 'center',
                        fontSize: '13px',
                        marginTop: '15px',
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          ...generalStyles.generalDisplay,
                          textAlign: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                        }}
                      >
                        {item.payments && item.payments.length > 0 ? (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                              cursor: 'pointer',
                            }}
                          >
                            {item.payments.map(item2 => (
                              <>
                                {
                                  getActiveLanguage(
                                    item2.payment_type.translations,
                                  ).name
                                }
                                ({item2.amount.formatted})
                                <br />
                              </>
                            ))}
                          </Grid>
                        ) : (
                          item.preset_payment_type && (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                ...generalStyles.generalDisplay,
                                textAlign: 'center',
                                fontSize: '13px',
                                cursor: 'pointer',
                              }}
                            >
                              {
                                getActiveLanguage(
                                  item?.preset_payment_type?.translations,
                                ).name
                              }
                            </Grid>
                          )
                        )}
                        <Grid
                          item
                          sx={{
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                            cursor: 'pointer',
                            marginTop: '5px',
                          }}
                        >
                          <Grid container>
                            <Grid>
                              {singlePriceFormatter(
                                (
                                  Number(item.total_amount.amount) -
                                  Number(item.total_discount.amount)
                                ).toString(),
                                1,
                                item.total_amount.currency || 'TRY',
                              ) || '₺0,00'}
                            </Grid>{' '}
                            {Number(item.paid_amount.amount) > 0 && (
                              <Grid color="#d32f2f" ml={1}>
                                | {item.paid_amount.formatted}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        color: '#d32f2f',
                        ...generalStyles.generalDisplay,
                        textAlign: 'center',
                        fontSize: '13px',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                      onClick={() => {
                        if (item.customer) {
                          getOrderLastTransactions(item.customer.id);
                        }
                        setIsShowLastOrders(
                          isShowLastOrders === item.id ? '' : item.id,
                        );
                        setOrder(item);
                        setOpenLastTransactions(true);
                      }}
                    >
                      {t('order.past_order_information')}
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.created_at)}
                  </TableCell>
                  {selectActive !== 0 && (
                    <TableCell align="center">
                      {item.ended_at ? formatDate(item.ended_at) : '-'}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {selectActive === 0 ? (
                      <>
                        <Select
                          variant="filled"
                          SelectDisplayProps={{
                            style: { paddingTop: 10, paddingBottom: 10 },
                          }}
                          sx={{
                            borderRadius: '20px',
                            background: getStatusColor(item.status_id),
                            color: 'white',
                            '.MuiSvgIcon-root ': {
                              color: 'white !important',
                            },
                            '&:hover': {
                              background: '#26acf8',
                            },
                            '& [aria-expanded=true]': {
                              background: getStatusColor(item.status_id),
                              borderRadius: '20px',
                            },
                            '& [aria-expanded=false]': {
                              background: getStatusColor(item.status_id),
                              borderRadius: '20px',
                            },
                            height: '30px',
                            fontSize: '12px',
                          }}
                          displayEmpty
                          disableUnderline
                          value={item.status_id}
                        >
                          {selectedOptions.map(option => (
                            <MenuItemComponent
                              onClick={() => {
                                if (option.id === 5) {
                                  if (!item.deliverer) {
                                    setOrderId(item.id);
                                    setStatusId(option.id);
                                    setDelivererModal(true);
                                  } else {
                                    handleSetStatus(item.id, 5);
                                  }
                                } else if (option.id === 16) {
                                  if (!item.is_paid) {
                                    setOrderId(item.id);
                                    setOrder(item);
                                    setOpen(true);
                                    setFastPaymentModal(true);
                                  } else {
                                    handleSetStatus(item.id, 16);
                                  }
                                } else {
                                  handleSetStatus(item.id, option.id);
                                }
                              }}
                              disabled={option.id === item.status_id}
                              key={option.id}
                              value={option.id}
                            >
                              {option.text}
                            </MenuItemComponent>
                          ))}
                        </Select>
                        {item.deliverer && (
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: '500',
                              mt: 1,
                            }}
                          >
                            Kurye: {item.deliverer.full_name}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Chip
                        sx={{ height: '25px', fontSize: '12px' }}
                        label={
                          selectActive === 1
                            ? t('order.completed')
                            : t('order.cancelled')
                        }
                        color="primary"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid container mt={1} justifyContent="space-around">
                      <Tooltip title={t('order.details.print')} arrow>
                        <IconButton
                          sx={generalStyles.icon}
                          onMouseDown={e => e.stopPropagation()}
                          onClick={() => handlePrintTable(item.id)}
                        >
                          <Icon name="Print" color="error" />
                        </IconButton>
                      </Tooltip>
                      {hasSetDelivererPermission &&
                        (item?.status_id === 1 || item?.status_id === 2) &&
                        item?.type_id === 3 &&
                        !item?.deliverer && (
                          <Tooltip
                            title={t('order.settings.select_deliverer')}
                            arrow
                          >
                            <IconButton
                              sx={generalStyles.icon}
                              onMouseDown={e => e.stopPropagation()}
                              onClick={() => {
                                setStatusId(item.status_id);
                                setOrderId(item?.id);
                                setOrder(item);
                                setDelivererId(item?.deliverer?.id || '');
                                setDelivererModal(true);
                              }}
                            >
                              <Icon name="TwoWheeler" color="error" />
                            </IconButton>
                          </Tooltip>
                        )}
                      {selectActive === 0 && (
                        <Tooltip
                          title={t('order.details.fast_transactions')}
                          arrow
                        >
                          <IconButton
                            sx={generalStyles.icon}
                            onMouseDown={e => e.stopPropagation()}
                            onClick={() => {
                              setOpen(true);
                              setOrderId(item.id);
                              setOrder(item);
                              setType(item.status_id);
                            }}
                          >
                            <Icon name="MoreHoriz" color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <Pagination
            sx={{ margin: '10px' }}
            count={Math.ceil(
              // @ts-ignore
              meta.total / meta.per_page,
            )}
            page={page}
            onChange={handleChange}
          />
        </Table>
      )}
    </Grid>
  );
}

export default OrdersTableHeader;
