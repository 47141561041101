/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type GeneralSettingFromValues = {
  sales_channel_id: string;
  is_auto_approve_order: boolean;
  is_auto_print_order: boolean;
  order_preparation_duration: number;
  api_key: string;
  seller_id: string;
  api_secret: string;
  restaurant_id: string;
};

const generalSettingsSchema: yup.SchemaOf<GeneralSettingFromValues> =
  yup.object({
    sales_channel_id: yup.string().required('generalSettings.name.required'),
    is_auto_approve_order: yup
      .boolean()
      .required('generalSettings.name.required'),
    is_auto_print_order: yup
      .boolean()
      .required('generalSettings.name.required'),
    order_preparation_duration: yup
      .number()
      .required('generalSettings.name.required'),
    api_key: yup.string().required('generalSettings.name.required'),
    seller_id: yup.string().required('generalSettings.name.required'),
    api_secret: yup.string().required('generalSettings.name.required'),
    restaurant_id: yup.string().required('generalSettings.name.required'),
  });

export const validationGeneralSettingsSchema = yupResolver(
  generalSettingsSchema,
);
