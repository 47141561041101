/** Dependencies */
import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';

/** Styles */
import styles from '../styles';

/** Components */
import getActiveLanguage from '../../../../../../../../helpers/getActiveLanguage';

/** Types */
import {
  IngredientGroupItems,
  PropertyGroupItems,
  PropertyName,
  Unit,
} from '../../../../../../../../models/Product';

interface Props {
  isSubmittedOrder: boolean;
  name: string;
  unit?: Unit | null;
  isMenu: boolean;
  propertyGroupItems?: PropertyGroupItems[];
  ingredientGroupItems?: IngredientGroupItems[];
  singlePropertyName: PropertyName;
  propertyNames: PropertyName[];
  nonIngredientId: string[];
  ingredientGroups: any[];
  createdName: string;
}

function ProductItemNameAndPropertyInfo({
  isSubmittedOrder,
  name,
  unit,
  isMenu,
  propertyGroupItems,
  ingredientGroupItems,
  singlePropertyName,
  propertyNames,
  nonIngredientId,
  ingredientGroups,
  createdName,
}: Props) {
  return (
    <Box>
      <Typography sx={styles.title}>{name}</Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: { xs: 12, sm: 11, lg: 14 } }}
      >
        {unit ? getActiveLanguage(unit.translations).name : ''}
      </Typography>
      {isSubmittedOrder && (
        <Typography
          variant="subtitle2"
          sx={{ fontSize: { xs: 11, sm: 11, lg: 12 } }}
        >
          {createdName}
        </Typography>
      )}

      {isSubmittedOrder && !isMenu && (
        <Typography sx={styles.properties} variant="subtitle2">
          <List>
            {propertyGroupItems?.map(propItem => (
              <ListItem sx={styles.priceInfo}>
                {propItem.product_property_group_item_id === null
                  ? propItem.name
                  : propItem.translations[0].name}
                {propItem.additional_price.amount !== '0' &&
                  ` + ${propItem.additional_price.formatted}`}
              </ListItem>
            ))}
          </List>
          <List>
            {ingredientGroupItems?.map(propItem => (
              <ListItem sx={styles.priceInfoUnderline}>
                {propItem.translations[0].name}
              </ListItem>
            ))}
          </List>
        </Typography>
      )}
      {!isSubmittedOrder && (
        <Typography sx={styles.properties} variant="subtitle2">
          <List>
            <ListItem sx={styles.priceInfo}>{singlePropertyName.name}</ListItem>
            {propertyNames.map(item => (
              <ListItem sx={styles.priceInfo}>{item.name}</ListItem>
            ))}
          </List>
          <List>
            {nonIngredientId?.length
              ? nonIngredientId.map(item => (
                  <ListItem sx={styles.priceInfoUnderline}>
                    {
                      ingredientGroups[0]?.items?.find(
                        (ing: { id: string }) => ing.id === item,
                      ).translations[0].name
                    }
                  </ListItem>
                ))
              : ''}
          </List>
        </Typography>
      )}
    </Box>
  );
}

export default ProductItemNameAndPropertyInfo;
