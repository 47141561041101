import api from '../api';
import { ListRes, ToggleRes, Integration } from './types';

const list = () => api.get<ListRes>('/integrations');

const toggle = (integrationId: string) =>
  api.post<ToggleRes>(`/integrations/${integrationId}/toggle`);

const integratorsService = {
  list,
  toggle,
};

export default integratorsService;
export type { Integration };
