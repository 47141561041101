/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

/** Components */
import Modal from '../../../../components/Modal';
import Form from './Form';

/** Hooks */
import useSettings from '../../../../hooks/useSettings';
import useKitchenGroups from '../../../../hooks/useKitchenGroups';

/** Types */
import { PrinterAddAndUpdateFormValues, validationSchema } from '../types';

/** Services */
import { isApiError } from '../../../../services/api/types';

interface Props {
  open: boolean;
  onClose: () => void;
  item?: any | null;
}

function PrinterAddModal({ open, onClose, item }: Props) {
  const { t } = useTranslation('printerSettings');
  const { enqueueSnackbar } = useSnackbar();
  const { createPrinterSettings, updatePrinterSettings, meProfile } =
    useSettings();
  const { kitchenGroups } = useKitchenGroups();

  const initialValues = {
    id: item?.id || '',
    name: item?.name || '',
    connection_type: item?.connection_type || 'network',
    char_per_line: item?.char_per_line || 80,
    ip_address: item?.ip_address || '',
    port: item?.port || '',
    path: item?.path || '',
    font_size: item?.font_size || 'medium',
    footer_description: item?.footer_description || '',
    kitchen_group_ids:
      item?.kitchen_groups?.map(
        (groupItem: { id: string }) => groupItem.id,
      ) || [kitchenGroups[0]?.id] ||
      [],
  };

  const { handleSubmit, control, reset, watch } =
    useForm<PrinterAddAndUpdateFormValues>({
      defaultValues: initialValues,
      resolver: validationSchema,
    });
  const [loading, setLoading] = useState(false);

  useEffect(() => reset(initialValues), [kitchenGroups, open]);

  const handleConfirm = () => {
    try {
      setLoading(true);
      if (item) {
        handleSubmit(async values => {
          await updatePrinterSettings({
            ...values,
            id: item.id,
          });
          onClose();
        })();
      } else {
        handleSubmit(async values => {
          await createPrinterSettings({
            ...values,
            id: '',
          });
          onClose();
        })();
      }
    } catch (error) {
      enqueueSnackbar(
        isApiError(error) ? error.message : t('common:unknownError' as any),
        {
          variant: 'error',
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={item ? t('printer.editModal.title') : t('printer.addModal.title')}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={onClose}
      loading={loading}
      negativeAction={t('printer.addModal.cancel')}
      positiveAction={
        item ? t('printer.addModal.edit') : t('printer.addModal.add')
      }
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
    >
      <Form
        control={control}
        watch={watch}
        activeLanguage={meProfile?.language || 'tr'}
      />
    </Modal>
  );
}

export default PrinterAddModal;
