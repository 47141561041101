import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusOption {
  id: number;
  text: string;
}

interface Order {
  statusId: number;
  typeId: number;
  integrator: { slug: string } | null;
  deliveryType?: string;
}

const useSelectedOptions = (orders: Order[]): StatusOption[][] => {
  const { t } = useTranslation('orders');

  return useMemo(() => {
    const getTranslatedOptions = (): StatusOption[] => [
      { id: 1, text: t('order.placed') },
      { id: 2, text: t('order.preparing') },
      { id: 3, text: t('order.ready') },
      { id: 5, text: t('order.picked_up') },
      { id: 16, text: t('order.completed') },
    ];

    return orders.map(order => {
      const { statusId, typeId, integrator, deliveryType } = order;
      const integratorSlug = integrator?.slug || '';
      const allOptions = getTranslatedOptions();

      const getFilteredOptions = (options: StatusOption[]): StatusOption[] => {
        // If the integrator slug is not 'kitchrest', return the options that have an id greater than or equal to the statusId
        if (integratorSlug !== 'kitchrest') {
          return options.filter(option => option.id >= statusId);
        }
        return options;
      };

      // New condition for Trendyol with GO delivery type
      if (integratorSlug === 'trendyol' && deliveryType === 'GO') {
        return getFilteredOptions(
          allOptions.filter(option => option.id !== 5 && option.id !== 16),
        );
      }

      if (typeId === 3) {
        if (statusId === 5) {
          return getFilteredOptions(allOptions);
        }
        return getFilteredOptions(
          allOptions.filter(option => option.id !== 16),
        );
      }

      if (typeId === 2) {
        if (statusId === 3) {
          return getFilteredOptions([
            { id: 1, text: t('order.placed') },
            { id: 2, text: t('order.preparing') },
            { id: 3, text: t('order.ready') },
            { id: 16, text: t('order.completed') },
          ]);
        }
        return getFilteredOptions(allOptions.filter(option => option.id <= 3));
      }

      return getFilteredOptions(allOptions.filter(option => option.id !== 16));
    });
  }, [orders, t]);
};

export default useSelectedOptions;
