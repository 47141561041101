import React from 'react';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  keyframes,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentModalCustomer from './PaymentModalCustomer';
import Button from '../../../../../../components/Button';
import Icon from '../../../../../../components/Icon';
import useProfile from '../../../../../../hooks/useProfile';
import styles from '../styles';
import keyHasPermission from '../../../../../../helpers/keyHasPermission';
import useSettings from '../../../../../../hooks/useSettings';

// Corrected Props interface
interface Props<T = unknown> {
  handleAddPaymentClick: (id: number, customer_id?: string) => void;
  selectedOrderTypeId: number;
  paymentCustomer: boolean;
  setPaymentCustomer: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
}

const blink = keyframes` from { color: white } to { color: red }`;

function PaymentModalRightSide<T>({
  handleAddPaymentClick,
  selectedOrderTypeId,
  paymentCustomer,
  setPaymentCustomer,
}: Props<T>) {
  const { meProfile } = useProfile();
  const { fetchMeWithPaymentTypes } = useSettings();
  const { t } = useTranslation('pos');

  const hasGetOrderPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );

  return (
    <Paper sx={styles.paymentModalPaper} elevation={3}>
      {t('modalMidSide.type')}
      <Divider />
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          width: '100%',
          margin: 0,
        }}
      >
        {meProfile?.paymentTypes?.map(
          item =>
            item.is_active && (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                key={item.id}
                sx={{ padding: '4px' }}
              >
                <Button
                  color="inherit"
                  fullWidth
                  sx={{
                    ...styles.paymentTypeButton,
                    height: '100%',
                    minHeight: '120px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start', // Changed from 'center'
                    alignItems: 'center',
                    padding: '8px', // Increased padding slightly
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => {
                    if (item.id === 9) {
                      setPaymentCustomer(true);
                    } else {
                      handleAddPaymentClick(item.id);
                    }
                  }}
                  disabled={!hasGetOrderPaymentPermission}
                >
                  <Box
                    position="relative"
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between" // Added to distribute space
                    alignItems="center"
                  >
                    {item.id === selectedOrderTypeId && (
                      <Box
                        sx={{
                          ...styles.paymentModalRightCircleType,
                          animation: `${blink} 2s linear infinite`,
                          position: 'absolute',
                          top: 0,
                          right: 0,
                        }}
                      >
                        <Icon name="Circle" />
                      </Box>
                    )}
                    <Box
                      mt={1}
                      mb={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      flexGrow={1}
                    >
                      <img
                        src={item.logo_url}
                        alt=""
                        style={{
                          width: '48px', // Slightly increased size
                          height: '48px',
                          objectFit: 'contain',
                          marginBottom: '8px', // Added margin below image
                        }}
                      />
                      <Typography
                        sx={{
                          width: '100%',
                          textAlign: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.2em',
                          maxHeight: '2.4em',
                          fontSize: '0.875rem', // Slightly reduced font size
                          flexGrow: 1, // Allow text to take remaining space
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
              </Grid>
            ),
        )}
      </Grid>
      <PaymentModalCustomer
        open={paymentCustomer}
        handleAddPaymentClick={(id, customer_id) =>
          handleAddPaymentClick(id, customer_id)
        }
        onClose={() => setPaymentCustomer(false)}
      />
    </Paper>
  );
}

export default PaymentModalRightSide;
