import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Modal from '../../../../components/Modal';
import generalStyles from '../../../../assets/stylesheets/generalStyles';
import { singlePriceFormatter } from '../../../../helpers/moneyFormatter';
import { PosTransaction } from '../../../../models/Pos';
import { GroupedDataColumnType } from '../../../../services/api/orders/types';
import useOrders from '../../../../hooks/useOrders';

interface Props {
  open: boolean;
  onClose: () => void;
  isShowLastOrders: string;
  item?: GroupedDataColumnType;
}

function LastTransactionsModal({
  open,
  onClose,
  isShowLastOrders,
  item,
}: Props) {
  const { t } = useTranslation('orders');
  const { getOrderLastTransactions, lastOrderTransactions } = useOrders();
  const hasRequestedRef = useRef(false);
  const isFirstRender = useRef(true);

  const handleClose = useCallback(() => {
    onClose();
    hasRequestedRef.current = false;
  }, [onClose]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      open &&
      isShowLastOrders === item?.id &&
      item?.customer?.id &&
      !hasRequestedRef.current
    ) {
      console.log('Fetching data...'); // For debugging
      getOrderLastTransactions(item.customer.id);
      hasRequestedRef.current = true;
    }
  }, [
    open,
    isShowLastOrders,
    item?.id,
    item?.customer?.id,
    getOrderLastTransactions,
  ]);

  const totalAmountCalculator = (
    transactions: PosTransaction[],
    currency: string,
  ) => {
    const totalPrice = transactions.reduce(
      (sum, transaction) => sum + Number(transaction.total_amount.amount),
      0,
    );

    return (
      singlePriceFormatter(totalPrice.toString(), 1, currency || 'TRY') ||
      '₺0,00'
    );
  };

  const totalDiscountCalculator = (
    transactions: PosTransaction[],
    currency: string,
  ) => {
    const totalDiscount = transactions.reduce(
      (sum, transaction) => sum + Number(transaction.total_discount.amount),
      0,
    );

    return (
      singlePriceFormatter(totalDiscount.toString(), 1, currency || 'TRY') ||
      '₺0,00'
    );
  };

  const renderContent = () => {
    if (!item) {
      return null;
    }

    if (!item.customer) {
      return <Grid fontSize={15}>{t('order.no_customer')}</Grid>;
    }

    if (isShowLastOrders !== item.id) {
      return null;
    }

    if (!lastOrderTransactions || lastOrderTransactions.length === 0) {
      return <Grid fontSize={15}>{t('order.no_past_transaction')}</Grid>;
    }

    return (
      <Grid mb={2} mt={2} container justifyContent="space-between">
        <Grid
          sx={{
            ...generalStyles.generalDisplay,
            fontSize: '15px',
            border: '1px solid #f3f3f3',
            textAlign: 'center',
            borderRadius: '5px',
          }}
          p={2}
          item
        >
          {lastOrderTransactions.length}
          <br />
          {t('order.total')}
          <br />
          {t('order.order_count')}
        </Grid>
        <Grid
          sx={{
            ...generalStyles.generalDisplay,
            fontSize: '15px',
            textAlign: 'center',
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
          }}
          p={2}
          item
        >
          {totalAmountCalculator(
            lastOrderTransactions,
            item.total_amount.currency,
          )}
          <br />
          {t('order.total')}
          <br />
          {t('order.order_amount')}
        </Grid>
        <Grid
          sx={{
            ...generalStyles.generalDisplay,
            fontSize: '15px',
            textAlign: 'center',
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
          }}
          p={2}
          item
        >
          {totalDiscountCalculator(
            lastOrderTransactions,
            item.total_amount.currency,
          )}
          <br />
          {t('order.total')}
          <br />
          {t('order.discount_amount')}
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      title={t('order.past_transactions_modal.title')}
      negativeAction={t('order.deliverer_modal.close')}
      onNegativeClick={handleClose}
    >
      <Grid>{renderContent()}</Grid>
    </Modal>
  );
}

export default LastTransactionsModal;
