/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack } from '@mui/material';

/** Components */
import Modal from '../../../../components/Modal';

/** Types */
import FormNumberInput from '../../../../components/FormNumberInput';
import useCustomers from '../../../../hooks/useCustomers';
import useSettings from '../../../../hooks/useSettings/useSettings';
import styles from '../../../../apps/Pos/Sections/OrderModal/styles';
import { PaymentList } from '../../../../services/api/definitions/customers/types';

interface Props {
  open: boolean;
  onClose: () => void;
  customerBalance?: () => void;
  customerId: string;
  itemAmount?: PaymentList | null;
}

function GetPaidModal({
  open,
  onClose,
  customerId,
  customerBalance,
  itemAmount,
}: Props) {
  const { t } = useTranslation('customerAddress');
  const { enqueueSnackbar } = useSnackbar();
  const { addGetPaid, updateGetPaid } = useCustomers();
  const { fetchGeneralFilterSettings, meProfile, fetchMeWithPaymentTypes } =
    useSettings();

  const [customerAmount, setCustomerAmount] = useState<string>('');
  const [selectPaymentType, setSelectPaymentType] = useState<string>('');

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      fetchGeneralFilterSettings(false);
    }
  }, [open]);

  useEffect(() => {
    if (itemAmount) {
      setCustomerAmount(
        (parseInt(itemAmount.amount.amount, 10) / 100).toFixed(2).toString(),
      );
      setSelectPaymentType(itemAmount?.payment_type?.id.toString());
    }
  }, [itemAmount]);

  const handleAddPaymentClick = async (id: string) => {
    let result: any;

    try {
      if (Number(customerAmount) <= 0) {
        enqueueSnackbar(t('actions.notNegativeNumber'), {
          variant: 'error',
        });
      } else if (!id) {
        enqueueSnackbar(t('actions.paymentTypeRequire'), {
          variant: 'error',
        });
      } else {
        if (itemAmount) {
          result = await updateGetPaid({
            paid_id: itemAmount?.id,
            customer_id: customerId,
            amount: customerAmount,
            payment_type_id: id,
          });
        } else {
          result = await addGetPaid({
            customer_id: customerId,
            amount: customerAmount,
            payment_type_id: id,
          });
        }

        if (result?.payload?.response?.success || result?.payload?.id) {
          enqueueSnackbar(t('common:success' as any), {
            variant: 'success',
            style: { whiteSpace: 'pre-line' },
          });
          onClose();
        }
        setIsSubmitted(false);
        setCustomerAmount('');
        setSelectPaymentType('');
        if (customerBalance) {
          customerBalance();
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.toString().slice(6), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      throw err;
    }
  };

  const handleClose = () => {
    onClose();
    setCustomerAmount('');
    setSelectPaymentType('');
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      title={itemAmount ? t('actions.paymentEdit') : t('actions.payment')}
      negativeAction={t('actions.cancel')}
      positiveAction={itemAmount ? t('actions.edit') : t('actions.confirm')}
      onPositiveClick={() => handleAddPaymentClick(selectPaymentType)}
      onNegativeClick={() => handleClose()}
    >
      <div>
        <Stack
          sx={{ flex: 1, '&:focus': { outline: 'none' }, outline: 'none' }}
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
        >
          <Grid container item>
            <Grid xs={12}>
              <FormNumberInput
                value={customerAmount}
                placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
                onChange={event => setCustomerAmount(event.target.value)}
                label={t('customerAddress.addCustomerPaid.amountPaid')}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                }}
                error={isSubmitted && !customerAmount}
                helperText={
                  isSubmitted && !customerAmount && t('actions.require')
                }
              />
            </Grid>
            <br />
            <Grid
              sx={{ mt: 3 }}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              {meProfile?.paymentTypes &&
                meProfile?.paymentTypes.map(
                  item =>
                    item.is_active &&
                    item.id !== 9 && (
                      <Grid sx={{ p: 1 }} item xs={6}>
                        <Button
                          color="inherit"
                          fullWidth
                          style={
                            item?.id?.toString() === selectPaymentType
                              ? { borderColor: 'blue' }
                              : { borderColor: '' }
                          }
                          sx={styles.paymentTypeButton}
                          onClick={() => {
                            setSelectPaymentType(item.id.toString());
                          }}
                        >
                          <Grid justifyContent="center">
                            <Grid>
                              <img
                                src={item.logo_url}
                                alt=""
                                width="40px"
                                height="40px"
                              />
                            </Grid>
                            <Grid>{item.name}</Grid>
                          </Grid>
                        </Button>
                      </Grid>
                    ),
                )}
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Modal>
  );
}

export default GetPaidModal;
