/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Divider,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem as MenuItemComponent,
  Select,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import integratorsService, {
  Integration,
} from '../../../../services/api/integrators/integrators';

interface Props {
  selectActive: number;
  setSelectActive: (value: ((prevState: number) => number) | number) => void;
  setPage: (value: ((prevState: number) => number) | number) => void;
  statusIds: number[];
  setStatusIds: (value: ((prevState: number[]) => number[]) | number[]) => void;
}

function OrdersTableHeader({
  selectActive,
  setSelectActive,
  setPage,
  statusIds,
  setStatusIds,
}: Props) {
  const { t } = useTranslation('orders');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration | null>(null);

  const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const ConfirmButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await integratorsService.list();
        // @ts-ignore
        setIntegrations(response.data.data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    fetchIntegrations();
  }, []);

  const handleIntegrationClick = (integration: Integration) => {
    setSelectedIntegration(integration);
    setDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (selectedIntegration) {
      try {
        await integratorsService.toggle(selectedIntegration.id);
        setIntegrations(prevIntegrations =>
          prevIntegrations.map(integration =>
            integration.id === selectedIntegration.id
              ? { ...integration, is_active: !integration.is_active }
              : integration,
          ),
        );
      } catch (error) {
        console.error('Error toggling integration:', error);
      }
    }
    setDialogOpen(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const sortOptions = [
    { id: 1, text: t('order.placed') },
    { id: 2, text: t('order.preparing') },
    { id: 3, text: t('order.ready') },
    { id: 5, text: t('order.picked_up') },
  ];

  const status = [
    {
      id: 'active',
      text: t('order.status.active'),
    },
    {
      id: 'completed',
      text: t('order.status.completed'),
    },
    {
      id: 'canceled',
      text: t('order.status.canceled'),
    },
  ];

  const getStatusText = (status_id: number) => {
    return status_id === 1
      ? t('order.placed')
      : status_id === 2
      ? t('order.preparing')
      : status_id === 3
      ? t('order.ready')
      : status_id === 5
      ? t('order.picked_up')
      : status_id === 16
      ? t('order.completed')
      : t('order.cancelled');
  };

  return (
    <Grid
      p={2}
      container
      sx={{ display: 'flex', alignItems: 'center' }}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Tabs
          value={selectActive}
          onChange={(_, value) => {
            setPage(1);
            if (value !== 0) {
              setStatusIds([]);
            }
            setSelectActive(value);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {status.map(item => (
            <Tab key={item.id} label={item.text} />
          ))}
        </Tabs>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}
        >
          {integrations.map(integration => (
            <Tooltip key={integration.id} title={integration.integrator.name}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0 4px',
                }}
              >
                <IconButton
                  onClick={() => handleIntegrationClick(integration)}
                  sx={{
                    opacity: integration.is_active ? 1 : 0.5,
                    transition: 'opacity 0.3s',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  <img
                    src={integration.integrator.logo}
                    alt={integration.integrator.name}
                    style={{ width: 40, height: 40 }}
                  />
                </IconButton>
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: integration.is_active
                      ? '#4caf50'
                      : '#f44336',
                    marginTop: -5,
                  }}
                />
              </div>
            </Tooltip>
          ))}
        </div>

        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('order.integration_dialog.title', {
              name: selectedIntegration?.integrator.name,
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selectedIntegration &&
                t('order.integration_dialog.description', {
                  name: selectedIntegration.integrator.name,
                  action: selectedIntegration.is_active
                    ? t('order.integration_dialog.deactivate')
                    : t('order.integration_dialog.activate'),
                  consequence: selectedIntegration.is_active
                    ? t('order.integration_dialog.close_restaurant')
                    : t('order.integration_dialog.open_restaurant'),
                })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={handleClose} variant="outlined">
              {t('order.integration_dialog.cancel')}
            </CancelButton>
            <ConfirmButton
              onClick={handleConfirm}
              variant="contained"
              autoFocus
            >
              {t('order.integration_dialog.confirm')}
            </ConfirmButton>
          </DialogActions>
        </Dialog>

        {selectActive === 0 && (
          <Select
            variant="filled"
            SelectDisplayProps={{
              style: { paddingTop: 10, paddingBottom: 10 },
            }}
            sx={{
              borderRadius: '20px',
              background: 'rgba(183,183,183,0.96)',
              color: 'white',
              '.MuiSvgIcon-root ': {
                color: 'white !important',
              },
              '& [aria-expanded=true]': {
                background: 'rgba(183,183,183,0.96)',
                borderRadius: '20px',
                height: '15px',
              },
              '& [aria-expanded=false]': {
                background: 'rgba(183,183,183,0.96)',
                borderRadius: '20px',
                height: '15px',
              },
              '& .MuiSelect-select .notranslate::after': {
                content: `"${t('order.filter.status_sub_header')}"`,
                opacity: 0.8,
                height: '15px',
              },
              height: '35px',
              width: '200px',
              fontSize: '13px',
            }}
            displayEmpty
            disableUnderline
            value={statusIds}
            placeholder="xxxx"
            renderValue={selected =>
              selected.length
                ? selected.map(
                    (item, index) =>
                      `${getStatusText(item)}${
                        index + 1 < selected.length ? ', ' : ''
                      } `,
                  )
                : t('order.filter.status_sub_header')
            }
            multiple
          >
            <ListSubheader sx={{ alignItems: 'center', textAlign: 'center' }}>
              {t('order.filter.status_sub_header')}
            </ListSubheader>
            <Divider />
            {sortOptions.map(group => (
              <MenuItemComponent
                onClick={() => {
                  if (statusIds.includes(group.id)) {
                    setStatusIds(statusIds.filter(item => item !== group.id));
                  } else {
                    setStatusIds([...statusIds, group.id]);
                  }
                }}
                key={group.text}
                value={group.text}
              >
                <Checkbox checked={statusIds.includes(group.id)} />
                <ListItemText primary={group.text} />
              </MenuItemComponent>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
  );
}

export default OrdersTableHeader;
