/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

/** Styles */
import { Grid, Box } from '@mui/material';
import styles from '../../../Register/styles';

/** Components */
import Button from '../../../../components/Button';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import LoadingState from '../../../../components/LoadingState';

/** Types */
import {
  validationGeneralSettingsSchema,
  GeneralSettingFromValues,
} from './types';

/** Hooks */
import useSettings from '../../../../hooks/useSettings';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useIntegrations from '../../../../hooks/useIntegrations';
import FormSwitch from '../../../../components/FormSwitch';
import { OptionsMemoWithName } from '../../../../helpers/optionMemo';

function GeneralSettingsForm() {
  const { t } = useTranslation('integrations');
  const { meProfile, fetchMe, channelSales, fetchChannelSales } = useSettings();
  const { fetchIntegrationSettings, settingsList, updateIntegrationSettings } =
    useIntegrations();

  const [loading, setLoading] = useState(false);

  const initialValues: GeneralSettingFromValues = {
    sales_channel_id: settingsList?.sales_channel_id || '',
    is_auto_approve_order: settingsList?.is_auto_approve_order || false,
    is_auto_print_order: settingsList?.is_auto_print_order || false,
    order_preparation_duration: settingsList?.order_preparation_duration || 0,
    api_key: settingsList?.settings?.api_key || '',
    seller_id: settingsList?.settings?.seller_id || '',
    api_secret: settingsList?.settings?.api_secret || '',
    restaurant_id: settingsList?.settings?.restaurant_id || '',
  };

  const { handleSubmit, control, reset } = useForm<GeneralSettingFromValues>({
    defaultValues: initialValues,
    resolver: validationGeneralSettingsSchema,
  });

  const handleClick = () => {
    handleSubmit(async values => {
      setLoading(true);
      try {
        setLoading(true);
        await updateIntegrationSettings(
          {
            sales_channel_id: values.sales_channel_id,
            is_auto_approve_order: values.is_auto_approve_order,
            is_auto_print_order: values.is_auto_print_order,
            order_preparation_duration: values.order_preparation_duration,
            settings: {
              api_key: values.api_key,
              seller_id: values.seller_id,
              api_secret: values.api_secret,
              restaurant_id: values.restaurant_id,
            },
          },
          'trendyol',
        );
        reset(initialValues);
        fetchMe();
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    fetchIntegrationSettings('trendyol');
    fetchChannelSales();
  }, []);

  useEffect(() => {
    reset(initialValues);
  }, [settingsList]);

  if (loading) {
    return <LoadingState />;
  }

  const hasUpdateGeneralSettings = keyHasPermission(
    'update-general-settings',
    meProfile?.permissions || [],
  );

  return (
    <Box
      sx={{
        py: 10,
        px: 10,
        paddingX: {
          xxs: 0,
          xs: 0,
          sm: 0,
          md: 3,
          lg: 15,
          xl: 20,
        },
      }}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="sales_channel_id"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  options={OptionsMemoWithName(channelSales)}
                  label={t('form.sales_channel_id.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                  underline
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="api_key"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  label={t('form.api_key.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="api_secret"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  label={t('form.api_secret.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="seller_id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormInput
                      label={t('form.seller_id.label')}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error
                          ? t(fieldState.error.message as any)
                          : undefined
                      }
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="restaurant_id"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormInput
                      label={t('form.restaurant_id.label')}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error
                          ? t(fieldState.error.message as any)
                          : undefined
                      }
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="order_preparation_duration"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormInput
                      label={t('form.order_preparation_duration.label')}
                      error={fieldState.invalid}
                      helperText={
                        fieldState.error
                          ? t(fieldState.error.message as any)
                          : t('form.order_preparation_duration.helperText')
                      }
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="is_auto_approve_order"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('form.is_auto_approve_order.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="is_auto_print_order"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('form.is_auto_print_order.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="right">
          <Button
            onClick={handleClick}
            sx={styles.submit}
            variant="contained"
            size="large"
            disabled={!hasUpdateGeneralSettings}
          >
            {t('actions.submit')}
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}

export default GeneralSettingsForm;
