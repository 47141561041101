/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher, { Channel } from 'pusher-js';

/** Components */
import OrderSettingModal from './widgets/OrderSettingModal';
import DelivererModal from './modals/DelivererModal';
import LastTransactionsModal from './modals/LastTransactionsModal';

/** Hooks */
import useOrders from '../../../hooks/useOrders';
import useProfile from '../../../hooks/useProfile';
import usePosOrder from '../../../hooks/usePosOrder';

/** Services */
import { GroupedDataColumnType } from '../../../services/api/orders/types';

/** Styles */
import OrdersTableHeader from './OrdersTable/OrdersTableHeader';
import OrdersTableContent from './OrdersTable/OrdersTableContent';

function Orders() {
  const {
    getGroupedOrders,
    setOrderStatus,
    groupedOrders,
    meta,
    setGroupedOrdersWithPusher,
  } = useOrders();

  const { addOrderTransactionPayment } = usePosOrder();
  const { meProfile } = useProfile();
  const { enqueueSnackbar } = useSnackbar();

  let pusher: Pusher | null = null;
  let channel: Channel | null = null;

  const { t } = useTranslation('orders');
  const [selectActive, setSelectActive] = useState(0);
  const [page, setPage] = useState(1);
  const [statusIds, setStatusIds] = useState<number[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<number>(0);
  const [fastPaymentModal, setFastPaymentModal] = useState(false);

  const [orderId, setOrderId] = useState<string>('');
  const [delivererId, setDelivererId] = useState<string>('');
  const [statusId, setStatusId] = useState<number>(0);
  const [order, setOrder] = useState<GroupedDataColumnType>();
  const [openLastTransactions, setOpenLastTransactions] =
    useState<boolean>(false);

  const [isShowLastOrders, setIsShowLastOrders] = useState('');
  const [isShowAddress, setIsShowAddress] = useState('');
  const [delivererModal, setDelivererModal] = useState(false);

  const getStatusType = (status_id: number) => {
    return status_id === 1
      ? 'placed'
      : status_id === 2
      ? 'preparing'
      : status_id === 3
      ? 'ready'
      : status_id === 5
      ? 'picked_up'
      : status_id === 16
      ? 'completed'
      : 'cancelled';
  };

  const handlePrintTable = async (tableId: string) => {
    await addOrderTransactionPayment({
      order_transaction_id: tableId,
      payment_action: 3,
      payments: [
        {
          amount: 0,
          payment_type_id: 1,
          remainder_amount: 0,
        },
      ],
    });
    enqueueSnackbar(t('order.details.print_success'), {
      variant: 'success',
    });
  };

  const debouncedAndFilteredSearch = async (
    pageValue?: number,
    status_ids?: number[],
  ) => {
    try {
      setLoading(true);
      if (meProfile?.activeBranch?.day_id) {
        await getGroupedOrders(
          meProfile?.activeBranch?.day_id,
          selectActive === 0,
          selectActive === 1,
          selectActive === 2,
          pageValue || page,
          status_ids || null,
        );
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    await debouncedAndFilteredSearch(value);
  };

  const handleSetStatus = async (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => {
    if (!isPickedUp) await setOrderStatus(table_id, status_id);
    setTimeout(() => {
      debouncedAndFilteredSearch();
    }, 500);
  };

  const handleFilterSubmit = () => {
    debouncedAndFilteredSearch();
  };

  const messageEventHandler = async (data: any) => {
    await setGroupedOrdersWithPusher(data, selectActive);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (meProfile?.activeBranch?.id) {
      const appKey = process.env.REACT_APP_PUSHER_APP_KEY || '';

      // @ts-ignore
      pusher = new Pusher(appKey, {
        cluster: 'eu',
      });

      const channelName = `orders-${meProfile?.activeBranch?.id}-${
        selectActive === 0
          ? 'active'
          : selectActive === 1
          ? 'completed'
          : 'canceled'
      }`;

      channel = pusher.subscribe(channelName);

      channel.bind('orders-event', messageEventHandler);

      return () => {
        if (channel) {
          channel.unbind('orders-event', messageEventHandler);
          // @ts-ignore
          pusher.unsubscribe(channelName);
        }
        if (pusher) {
          pusher.disconnect();
        }
      };
    }
  }, [groupedOrders, selectActive, meProfile]);

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [meProfile, selectActive]);

  useEffect(() => {
    if (selectActive === 0) {
      debouncedAndFilteredSearch(undefined, statusIds);
    }
  }, [statusIds]);

  return (
    <Grid>
      <br />
      <br />
      <Grid
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          overflowX: 'auto',
        }}
      >
        <OrdersTableHeader
          selectActive={selectActive}
          setSelectActive={setSelectActive}
          setPage={setPage}
          statusIds={statusIds}
          setStatusIds={setStatusIds}
        />
        <OrdersTableContent
          selectActive={selectActive}
          page={page}
          handleChange={handleChange}
          handlePrintTable={handlePrintTable}
          handleSetStatus={handleSetStatus}
          setType={setType}
          setOrder={setOrder}
          setOrderId={setOrderId}
          setOpen={setOpen}
          setDelivererModal={setDelivererModal}
          setDelivererId={setDelivererId}
          setStatusId={setStatusId}
          setFastPaymentModal={setFastPaymentModal}
          setOpenLastTransactions={setOpenLastTransactions}
          isShowLastOrders={isShowLastOrders}
          setIsShowLastOrders={setIsShowLastOrders}
          isShowAddress={isShowAddress}
          setIsShowAddress={setIsShowAddress}
          groupedOrders={groupedOrders}
          meta={meta}
        />
      </Grid>
      <OrderSettingModal
        open={open}
        onClose={() => {
          setOpen(false);
          setOrderId('');
        }}
        handlePrintTable={handlePrintTable}
        orderId={order?.id || orderId}
        order={order}
        type={getStatusType(type)}
        handleSetStatus={handleSetStatus}
        handleFilterSubmit={handleFilterSubmit}
        fastPaymentModal={fastPaymentModal}
        setFastPaymentModal={setFastPaymentModal}
      />
      <DelivererModal
        open={delivererModal}
        onClose={() => {
          setDelivererModal(false);
          setOrderId('');
          setStatusId(0);
        }}
        orderId={orderId || order?.id || ''}
        activeDelivererId={order ? order?.deliverer?.id : delivererId}
        handleSetStatus={handleSetStatus}
        statusId={statusId || order?.status_id || 0}
      />
      <LastTransactionsModal
        open={openLastTransactions}
        onClose={() => {
          setOpenLastTransactions(false);
          setIsShowLastOrders('');
        }}
        isShowLastOrders={isShowLastOrders}
        item={order}
      />
    </Grid>
  );
}

export default Orders;
