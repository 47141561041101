import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';
import UtilitiesBar from '../../components/UtilitiesBar';
import useBranches from '../../hooks/useBranches';
import { EmptySvg } from '../../components/svg';
import DetailModal from './DetailModal';
import { BranchApps } from '../../services/api/utilities/types';

interface AppCardProps {
  item: BranchApps;
  onClick: () => void;
}

function AppCard({ item, onClick }: AppCardProps): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation('apps');

  return (
    <Box
      onClick={onClick}
      sx={{
        p: 2,
        border: '1px solid',
        borderRadius: 1,
        backgroundColor: 'white',
        borderColor: theme.palette.divider,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor:
          item.subscription_info?.is_installed && !item.redirect_url
            ? 'default'
            : 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box
          sx={{
            width: '60px',
            height: '60px',
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {item.logo_url ? (
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
              src={item.logo_url}
              alt={item.name}
            />
          ) : (
            <EmptySvg />
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
            {item.name}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: '10px',
              backgroundColor: item.subscription_info?.is_installed
                ? theme.palette.action.selected
                : theme.palette.success.main,
              color: 'white',
            }}
          >
            {item.subscription_info?.is_installed
              ? t('installed')
              : t('install')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

interface GroupComponentProps {
  groupData: BranchApps[];
  onAppClick: (item: BranchApps) => void;
}

function GroupComponent({
  groupData,
  onAppClick,
}: GroupComponentProps): JSX.Element {
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ color: 'text.primary', mr: 1 }}>
          {groupData[0].category}
        </Typography>
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: 'success.main',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.75rem',
          }}
        >
          {groupData.length}
        </Box>
      </Box>
      <Grid container spacing={2}>
        {groupData.map(item => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
            <AppCard item={item} onClick={() => onAppClick(item)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

interface GroupedComponentProps {
  groupedData: Record<string, BranchApps[]>;
  onAppClick: (item: BranchApps) => void;
}

function GroupedComponent({
  groupedData,
  onAppClick,
}: GroupedComponentProps): JSX.Element {
  return (
    <Paper sx={{ p: 3 }} elevation={3}>
      {Object.keys(groupedData).map(groupKey => (
        <GroupComponent
          key={groupKey}
          groupData={groupedData[groupKey]}
          onAppClick={onAppClick}
        />
      ))}
    </Paper>
  );
}

function Apps(): JSX.Element {
  const { t } = useTranslation('apps');
  const { getApps, branchApps } = useBranches();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [slug, setSlug] = useState('');
  const [images, setImages] = useState<string[]>(['']);

  useEffect(() => {
    async function fetchApps() {
      setLoading(true);
      setError(null);
      try {
        await getApps();
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    }

    fetchApps();
  }, []); // Empty dependency array ensures this runs only once on mount

  const groupedData: Record<string, BranchApps[]> = branchApps.reduce(
    (acc: Record<string, BranchApps[]>, item) => {
      const newAcc = { ...acc };
      const groupKey = item.category;
      if (!newAcc[groupKey]) {
        newAcc[groupKey] = [];
      }
      newAcc[groupKey].push(item);
      return newAcc;
    },
    {},
  );

  const handleAppClick = (item: BranchApps) => {
    if (!item.subscription_info?.is_installed) {
      setDescription(item.description);
      setRedirectUrl(item.redirect_url);
      setSlug(item.slug);
      setImages(item.screenshot_urls);
      setOpen(true);
    } else if (item.redirect_url) {
      navigate(`${item.redirect_url}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('apps')}
        leftIconName="Apps"
      />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
          }}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <GroupedComponent
          groupedData={groupedData}
          onAppClick={handleAppClick}
        />
      )}
      <DetailModal
        open={open}
        onClose={() => setOpen(false)}
        images={images}
        description={description}
        slug={slug}
        redirectUrl={redirectUrl}
      />
    </Box>
  );
}

export default Apps;
